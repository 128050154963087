import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

import useCommonStyles from "../../library/useCommonStyles"
import { Link } from "../i18n"

import { AllWordpressPost } from "./types"
import { formatDate } from "./utils"

type Props = {
  data: AllWordpressPost
}

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: "30vw",
    padding: theme.spacing(2),
  },
  image: {
    maxHeight: "130px",
    maxWidth: "130px",
  },
}))

export default function Posts({ data }: Props) {
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  return (
    <Grid container>
      {data.allWpPost.edges.map((edge) => (
        <Grid item key={edge.node.id} sm>
          <Link className={commonClasses.link} to={`/${edge.node.slug}`}>
            <div className={classes.container}>
              <Grid alignItems="center" container direction="row" wrap="nowrap">
                {edge.node.featuredImage?.node && (
                  <img
                    alt=""
                    className={classes.image}
                    src={edge.node.featuredImage.node.sourceUrl}
                  />
                )}
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  style={{ minHeight: "130px" }}
                >
                  <Grid item>
                    <Typography component="h3" variant="subtitle1">
                      {edge.node.title}
                    </Typography>
                  </Grid>
                  <Grid
                    className={commonClasses.colorSecondary}
                    dangerouslySetInnerHTML={{ __html: edge.node.excerpt }}
                    item
                  />
                  <Grid className={commonClasses.colorSecondary} item>
                    <Typography>{formatDate(edge.node.date)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}
