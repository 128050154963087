import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import { AllWordpressPost, PostsRaw } from "../components/LatestPosts"
import TextPage from "../pagesLayouts/TextPage"
import { WordpressPageQuery } from "../types/schema"

export default function NewsPl() {
  const query = graphql`
    query {
      wpPage(language: { slug: { eq: "pl" } }, slug: { eq: "news" }) {
        ...WordpressPageFragment
      }
      allWpPost(
        filter: { language: { slug: { eq: "pl" } } }
        sort: { fields: date, order: DESC }
      ) {
        edges {
          node {
            date
            excerpt
            featuredImage {
              node {
                sourceUrl
              }
            }
            id
            slug
            title
          }
        }
      }
    }
  `
  const data = useStaticQuery<AllWordpressPost & WordpressPageQuery>(query)

  return (
    <TextPage data={data} language="pl">
      <PostsRaw data={data} />
    </TextPage>
  )
}
