import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import LatestPosts from "./LatestPosts"
import { LatestPostsQuery } from "./types"

const translation = {
  readMore: "CZYTAJ WIĘCEJ",
}

export default function LatestPostsPl() {
  const postsQuery = graphql`
    query {
      allWpPost(
        filter: { language: { slug: { eq: "pl" } } }
        limit: 2
        sort: { fields: date, order: DESC }
      ) {
        edges {
          node {
            date
            excerpt
            featuredImage {
              node {
                sourceUrl
              }
            }
            id
            slug
            title
          }
        }
      }
      wpPage(language: { slug: { eq: "pl" } }, slug: { eq: "homepage-news" }) {
        title
        id
        content
      }
    }
  `

  const data = useStaticQuery<LatestPostsQuery>(postsQuery)

  return <LatestPosts data={data} translation={translation} />
}
