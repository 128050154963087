import { Typography } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import React from "react"

import useCommonStyles from "../../library/useCommonStyles"
import { Link } from "../i18n"

import Posts from "./Posts"
import { LatestPostsQuery } from "./types"

type Props = {
  data: LatestPostsQuery
  translation: {
    readMore: string
  }
}

export default function LatestPosts({ data, translation }: Props) {
  const commonClasses = useCommonStyles()

  if (data == null) {
    return null
  }

  return (
    <Container className={commonClasses.pageContainer}>
      {data.wpPage && (
        <>
          <Typography
            className={commonClasses.colorPrimary}
            component="h2"
            dangerouslySetInnerHTML={{
              __html: data.wpPage.title,
            }}
            variant="h2"
          />
          <Typography
            className={commonClasses.threeFours}
            color="secondary"
            component="h2"
            dangerouslySetInnerHTML={{
              __html: data.wpPage.content,
            }}
          />
        </>
      )}

      <Divider style={{ margin: "2em 0" }} />

      <Posts data={data} />

      <Link className={commonClasses.link} to="/news">
        <Button className={commonClasses.colorPrimary}>
          {translation.readMore}
        </Button>
      </Link>
    </Container>
  )
}
